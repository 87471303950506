<template>
	<div class="wrapper">
		<nav id="sidebar" class="sidebar">
			<div class="sidebar-content js-simplebar">
				<a class="sidebar-brand" href="#">
					<img src="../assets/auth/img/logos/logo-ixasia-index-white.png" style="width:175px;" class="img-fluid" alt="IX Index Asia">
				</a>

				<ul class="sidebar-nav">
					<li class="sidebar-header">
						Dashboard
					</li>
<!-- 					<li class="sidebar-item">
						<router-link class="sidebar-link" to="/real-time/index">
							<font-awesome-icon :icon="['fas', 'key']" class="align-middle" /> <span class="align-middle">Real Time Index</span>
						</router-link>
					</li>
					<li class="sidebar-item">
						<a class="sidebar-link" href="#">
							<font-awesome-icon :icon="['fas', 'key']" class="align-middle" /> <span class="align-middle">Delayed Index</span>
						</a>
					</li> -->
					<li class="sidebar-item">
						<router-link class="sidebar-link" to="/real-time/weighting">
							<font-awesome-icon :icon="['fas', 'key']" class="align-middle" /> <span class="align-middle">Real Time Weighting</span>
						</router-link>
					</li>
					<li class="sidebar-item">
						<router-link class="sidebar-link" to="/dashboard">
							<font-awesome-icon :icon="['fas', 'key']" class="align-middle" /> <span class="align-middle">API Tokens</span>
						</router-link>
					</li>
					<li class="sidebar-item">
						<a class="sidebar-link" href="https://documenter.getpostman.com/view/16191840/TzeTH93W" target="_blank">
							<font-awesome-icon icon="book" class="align-middle" /> <span class="align-middle">API Documentation</span>
						</a>
					</li>
				</ul>
			</div>
		</nav>
		<div class="main">
			<nav class="navbar navbar-expand navbar-light navbar-bg">
				<!-- <a class="sidebar-toggle">
					<i class="hamburger align-self-center"></i>
				</a> -->

				<div class="navbar-collapse collapse">
					<ul class="navbar-nav navbar-align">
<!-- 						<li class="nav-item dropdown">
							<a class="nav-icon dropdown-toggle" href="#" id="messagesDropdown" data-toggle="dropdown">
								<div class="position-relative">
									<i class="align-middle" data-feather="message-circle"></i>
									<span class="indicator">4</span>
								</div>
							</a>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="messagesDropdown">
								<div class="dropdown-menu-header">
									<div class="position-relative">
										4 New Messages
									</div>
								</div>
								<div class="list-group">
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<img src="img/avatars/avatar-5.jpg" class="avatar img-fluid rounded-circle" alt="Ashley Briggs">
											</div>
											<div class="col-10 pl-2">
												<div class="text-dark">Ashley Briggs</div>
												<div class="text-muted small mt-1">Nam pretium turpis et arcu. Duis arcu tortor.</div>
												<div class="text-muted small mt-1">15m ago</div>
											</div>
										</div>
									</a>
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<img src="img/avatars/avatar-2.jpg" class="avatar img-fluid rounded-circle" alt="Carl Jenkins">
											</div>
											<div class="col-10 pl-2">
												<div class="text-dark">Carl Jenkins</div>
												<div class="text-muted small mt-1">Curabitur ligula sapien euismod vitae.</div>
												<div class="text-muted small mt-1">2h ago</div>
											</div>
										</div>
									</a>
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<img src="img/avatars/avatar-4.jpg" class="avatar img-fluid rounded-circle" alt="Stacie Hall">
											</div>
											<div class="col-10 pl-2">
												<div class="text-dark">Stacie Hall</div>
												<div class="text-muted small mt-1">Pellentesque auctor neque nec urna.</div>
												<div class="text-muted small mt-1">4h ago</div>
											</div>
										</div>
									</a>
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<img src="img/avatars/avatar-3.jpg" class="avatar img-fluid rounded-circle" alt="Bertha Martin">
											</div>
											<div class="col-10 pl-2">
												<div class="text-dark">Bertha Martin</div>
												<div class="text-muted small mt-1">Aenean tellus metus, bibendum sed, posuere ac, mattis non.</div>
												<div class="text-muted small mt-1">5h ago</div>
											</div>
										</div>
									</a>
								</div>
								<div class="dropdown-menu-footer">
									<a href="#" class="text-muted">Show all messages</a>
								</div>
							</div>
						</li> -->
						<!-- <li class="nav-item dropdown">
							<a class="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-toggle="dropdown">
								<div class="position-relative">
									<i class="align-middle" data-feather="bell-off"></i>
								</div>
							</a>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="alertsDropdown">
								<div class="dropdown-menu-header">
									4 New Notifications
								</div>
								<div class="list-group">
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<i class="text-danger" data-feather="alert-circle"></i>
											</div>
											<div class="col-10">
												<div class="text-dark">Update completed</div>
												<div class="text-muted small mt-1">Restart server 12 to complete the update.</div>
												<div class="text-muted small mt-1">2h ago</div>
											</div>
										</div>
									</a>
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<i class="text-warning" data-feather="bell"></i>
											</div>
											<div class="col-10">
												<div class="text-dark">Lorem ipsum</div>
												<div class="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
												<div class="text-muted small mt-1">6h ago</div>
											</div>
										</div>
									</a>
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<i class="text-primary" data-feather="home"></i>
											</div>
											<div class="col-10">
												<div class="text-dark">Login from 192.186.1.1</div>
												<div class="text-muted small mt-1">8h ago</div>
											</div>
										</div>
									</a>
									<a href="#" class="list-group-item">
										<div class="row no-gutters align-items-center">
											<div class="col-2">
												<i class="text-success" data-feather="user-plus"></i>
											</div>
											<div class="col-10">
												<div class="text-dark">New connection</div>
												<div class="text-muted small mt-1">Anna accepted your request.</div>
												<div class="text-muted small mt-1">12h ago</div>
											</div>
										</div>
									</a>
								</div>
								<div class="dropdown-menu-footer">
									<a href="#" class="text-muted">Show all notifications</a>
								</div>
							</div>
						</li>
						<li class="nav-item dropdown">
							<a class="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-toggle="dropdown">
								<i class="align-middle" data-feather="settings"></i>
							</a>
							<a class="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-toggle="dropdown">
								<img src="img/avatars/avatar.jpg" class="avatar img-fluid rounded-circle mr-1" alt="Chris Wood" /> <span class="text-dark">Chris Wood</span>
							</a>
							<div class="dropdown-menu dropdown-menu-right">
								<a class="dropdown-item" href="pages-profile.html"><i class="align-middle mr-1" data-feather="user"></i> Profile</a>
								<a class="dropdown-item" href="#"><i class="align-middle mr-1" data-feather="pie-chart"></i> Analytics</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" href="pages-settings.html">Settings & Privacy</a>
								<a class="dropdown-item" href="#">Help</a>
								<a class="dropdown-item" href="#">Sign out</a>
							</div>
						</li> -->
						<li class="nav-item dropdown">
							<router-link :to="{path:'/logout'}" class="nav-icon">
								<font-awesome-icon icon="sign-out-alt" class="align-middle" />
							</router-link>
						</li>
					</ul>
				</div>
			</nav>

			<main class="content">
				<router-view></router-view>
			</main>

			<div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-hidden="true">
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Default modal</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body m-3">
							<p class="mb-0">Use Bootstrap’s JavaScript modal plugin to add dialogs to your site for lightboxes, user notifications, or completely custom content.</p>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
			<b-modal id="terms-modal" title="Terms of Service" ok-only ok-title="Close" button-size="sm">
				<p style="font-size:14px;">
					To receive the testing API from IX Asia Indexes Company Limited (IXAI) as an information vendor of IXAI, you must agree the following terms:
				</p>
				<p style="font-size:12px;">
						<ol>
							<li>The API is only for testing purpose, IXAI shall have no obligation to maintain or to continue to publish the information contained in the API or to supply any corrections, updates, or releases in connection therewith.  </li>

							<li>For the test API, no redistribution is allowed without the permission of IXAI, including but not limited to the API link itself, or any information contained in the API. </li>

							<li>IXAI ensures the accuracy of this API information to the best of its endeavours. However, IXAI does not guarantee the accuracy and accepts no liability (whether in tort or contract or otherwise) for any damage or loss arising from inaccuracies or omissions. </li>

							<li>For any other terms regarding to the testing API, IXAI reserves the right of final interpretation.   </li>
						</ol>
				</p>

			</b-modal>

			<footer class="footer">
				<div class="container-fluid">
					<div class="row text-muted">
						<div class="col-6 text-left">
							<ul class="list-inline">
								<li class="list-inline-item">
									<a class="text-muted" href="https://ix-index.com/contact.html" target="_blank">Support</a>
								</li>
								<!-- <li class="list-inline-item">
									<a class="text-muted" href="#">Help Center</a>
								</li> -->
								<!-- <li class="list-inline-item">
									<a class="text-muted" href="#">Privacy</a>
								</li> -->
								<li class="list-inline-item">
									<a class="text-muted" href="#" v-b-modal.terms-modal>Terms of Service</a>
								</li>
							</ul>
						</div>
						<div class="col-6 text-right">
							<p class="mb-0">
								&copy; 2021 - <a href="#" class="text-muted">IX Asia Indexes</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	</div>
</template>
<script>
	export default {
		name: "DashboardLayout",
		mounted() {

		}
	}
</script>
<style>
@import '../assets/dashboard/css/light.css';
ol > li{
	padding-top: 20px;
}
</style>

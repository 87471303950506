<template>
	<div>
		<div class="loader">
			<div class="loading-animation"></div>
		</div>

		<section class="min-vh-100 py-5">
			<div class="container">
				<div class="row justify-content-center mb-md-6">
					<div class="col-auto">
						<a href="#">
							<img src="../../assets/auth/img/logos/logo-ixasia-index.png" class="img-fluid" alt="IX Index Asia">
						</a>
					</div>
				</div>
				<div class="row justify-content-center" v-if="validations.password && validations.password_confirmation">
					<div class="col-auto">
						<div class="alert alert-danger">
							Password and Confirm Password are not the same.
						</div>
					</div>
				</div>
				<div class="row justify-content-center" v-if="validations.checkbox">
					<div class="col-auto">
						<div class="alert alert-danger">
							You must agree the terms and conditions before continuing the registration.
						</div>
					</div>
				</div>
				<div class="row justify-content-center pt-6">
					<div class="col-xl-4 col-lg-5 col-md-6">
						<div class="text-center mb-4">
							<h1 class="mb-1" style="font-size:2.2rem">Create Account</h1>
						</div>
						<form @submit.prevent="submitRegistrationForm">
							<div class="form-group">
								<input type="text" v-model="form.first_name" placeholder="First Name" class="form-control" v-bind:class="{'is-invalid': validations.first_name}">
							</div>
							<div class="form-group">
								<input type="text" v-model="form.last_name" placeholder="Last Name" class="form-control" v-bind:class="{'is-invalid': validations.last_name}">
							</div>
							<div class="form-group">
								<input type="text" v-model="form.phone" placeholder="Phone Number" class="form-control" v-bind:class="{'is-invalid': validations.phone}" minlength="8">
							</div>
							<div class="form-group">
								<input type="text" v-model="form.company_name" placeholder="Company Name" class="form-control" v-bind:class="{'is-invalid': validations.company_name}">
							</div>
							<div class="form-group">
								<input type="email" v-model="form.email" placeholder="Email Address" class="form-control" v-bind:class="{'is-invalid': validations.email}">
							</div>
							<div class="form-group">
								<input type="password" v-model="form.password" placeholder="Password" class="form-control" v-bind:class="{'is-invalid': validations.password}">
								<small class="text-muted">Must be at least 8 characters</small>
							</div>
							<div class="form-group">
								<input type="password" v-model="form.password_confirmation" placeholder="Confirm password" class="form-control" v-bind:class="{'is-invalid': validations.password_confirmation}">
							</div>
							<div class="form-group">
								<vue-recaptcha size="visible" ref="recaptcha" :sitekey="sitekey" :loadRecaptchaScript="true" @verify="verified" />
							</div>
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="signup-agree" v-model="checkbox" value="1" v-bind:class="{'is-invalid': validations.checkbox}">
								<label class="custom-control-label text-small text-muted" for="signup-agree">I agree to the 
									<a href="#">
										Terms &amp; Conditions
									</a>
								</label>
							</div>
							<div class="form-group">
								<button class="btn-block btn btn-primary" type="submit">Register</button>
							</div>
							<hr>
							<div class="text-center text-small text-muted">
								<span>Already have an account yet? <a href="/login">Sign In</a>
								</span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import VueRecaptcha from 'vue-recaptcha';

	export default {
		name: "Register",
		components:{
			VueRecaptcha,
		},
		mounted() {
			console.log('Component mounted.');
		},
		data()
		{
			return {
				form:{
					first_name: null,
					last_name: null,
					phone: null,
					company_name: null,
					email: null,
					password: null,
					password_confirmation: null,
					recaptcha_verification:null,
				},
				checkbox:null,
				sitekey:'6LcA2t4bAAAAAB3pnilA6WWNRWeSy2J_Znwy0isd',
				validations:{
					first_name: false,
					last_name: false,
					phone: false,
					company_name: false,
					email: false,
					password: false,
					password_confirmation: false,
					checkbox:false,
				}
			}
		},
		methods:{
			submitRegistrationForm()
			{
				if(!this.validateData())return false;
				this.$axios.post(`${process.env.VUE_APP_API_URL}/register/`, this.form)
				.then(response => {
					const data = response.data;
					localStorage.setItem('access_token', data.tokens.access_token);
					localStorage.setItem('refresh_token', data.tokens.refresh_token);
					this.$axios.defaults.headers.Authorization = 'Bearer '+localStorage.getItem('access_token')
					this.$toast.success('Registered Successfully.');
					this.$router.push({name: 'Dashboard'});
				})
				.catch(error => {
					this.$toast.error(error.response.data.message)
					this.resetRecaptcha();
				})
			},
			validateData()
			{
				Object.keys(this.form).forEach(item => this.validations[item] = false);
				const hasEmpty = Object.keys(this.form).some((item) => {
					if(this.form[item] == null)
					{
						this.validations[item] = true;
						if(item == 'recaptcha_verification')
							this.$toast.error("Please check the recaptcha box.")
						return true;
					}
					this.validations[item] = false;
					return false;
				});
				const same_password = this.form.password == this.form.password_confirmation;
				const not_checked_terms = this.checkbox == null || !this.checkbox;
				if(!same_password)
				{
					this.validations.password = true;
					this.validations.password_confirmation = true;
				}
				if(not_checked_terms)
				{
					this.validations.checkbox = true;
				}
				return !hasEmpty && same_password && !not_checked_terms;
			},
			verified(verify_code)
			{
				this.form.recaptcha_verification = verify_code;
			},
			resetRecaptcha()
			{
				this.$refs.recaptcha.reset();
				this.form.recaptcha_verification = null;
			}
		}
	}
</script>
<style>
@import '../../assets/auth/css/theme.css';

</style>

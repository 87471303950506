<template>
	<div class="container-fluid p-0">
		<div class="row mb-2 mb-xl-3">
			<div class="col-auto d-none d-sm-block">
				<h3>Real Time Index</h3>
			</div>
		</div>
		<!-- <div class="row" v-if="accessToken != null">
			<div class="col-md-12">
				<div class="alert alert-success">Your new API token has been created.</div>
			</div>
		</div> -->
		<div class="row" v-if="error != null">
			<div class="col-md-12">
				<div class="alert alert-danger">{{ error }}</div>
			</div>
		</div>
		<div class="row row-cols-md-auto align-items-center" v-if="accessToken != null">
			<div class="col-md-12">
				<label>Your new API token</label>
			</div>
			<div class="col-6">
				<input type="text" class="form-control" v-model="accessToken" readonly>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-info btn-sm" v-clipboard:copy="accessToken"><font-awesome-icon icon="clipboard" /> Copy</button>
			</div>
			<div class="col-md-12">
				<small id="emailHelp" class="form-text text-muted">Make sure you save it - you won't be able to access it again. Please refer to the API documentation for instructions on how to use it. Do not share it with others.</small>
			</div>
			<div class="col-md-12">
				<br>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "RealTimeIndex",
		mounted() {
			// this.$toast.success('f');
			this.$axios.get(`${process.env.VUE_APP_API_URL}/v2/user/token`)
			.then(response => {
				this.tokens = response.data.data;
			})
			.catch(error => {
				console.log(error);
			})
		},
		data()
		{
			return {
				tokens:[],
				tokenName:null,
				accessToken:null,
				error:null,
				issuing:false,
				revoking:false,
			}
		},
		methods:{
			issueToken()
			{
				if(this.issuing)return;
				this.issuing = true;
				this.$axios.post(`${process.env.VUE_APP_API_URL}/v2/user/token`, {name: this.tokenName})
				.then(response => {
					const data = response.data.data;
					this.tokens.unshift(data.token);
					this.accessToken = data.accessToken;
					this.error = null;
					this.$toast.success('Token Generated.')
				})
				.catch(error => {
					console.log(error);
					// this.error = error.response.data.error;
					this.accessToken = null;
					this.$toast.error(error.response.data.message);
				})
				.finally(() => this.issuing = false)
			},
			revokeToken(token, index)
			{
				if(confirm("Are you sure you want to delete this token?"))
				{
					if(this.revoking)return;
					this.revoking = true;
					this.$axios.delete(`${process.env.VUE_APP_API_URL}/v2/user/token/${token}`)
					.then(() => {
						this.tokens.splice(index, 1);
						this.$toast.success('Token Revoked.')
					})
					.catch(error => {
						console.log(error);
						this.$toast.error(error.response.data.message);
					})
					.finally(() => this.revoking = false)
				}
			},
		}
	}
</script>

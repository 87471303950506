
<template>
	<div>
		<div class="loader">
			<div class="loading-animation"></div>
		</div>

		<section class="min-vh-100 py-5">
			<div class="container">
				<div class="row justify-content-center mb-md-6">
					<div class="col-auto">
					<a href="#">
						<img src="../../assets/auth/img/logos/logo-ixasia-index.png" class="img-fluid" alt="IX Index Asia">
					</a>
					</div>
				</div>
				<div class="row justify-content-center pt-6">
					<div class="col-xl-4 col-lg-5 col-md-6">
						<div class="text-center mb-4">
							<h1 class="mb-1">Welcome back</h1>
							<span>Enter your account details below</span>
						</div>
						<form @submit.prevent="submitLogin">
							<div class="form-group">
								<input type="email" placeholder="Email Address" class="form-control" v-bind:class="{'is-invalid': email_error != null}" v-model="username">
								<span v-if="email_error" class="invalid-feedback text-danger" role="alert">
									<strong>{{ email_error }}</strong>
								</span>
							</div>
							<div class="form-group">
								<input type="password" placeholder="Password" class="form-control" v-bind:class="{'is-invalid': email_error != null}" v-model="password">
								<small><a href="#">Forgot your password?</a>
								</small>
							</div>
							<div class="form-group">
								<button class="btn-block btn btn-primary" type="submit">Sign in</button>
							</div>
							<hr>
							<div class="text-center text-small text-muted">
								<span>Do not have an account? <a href="/register">Register</a>
								</span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	export default {
		name: "Login",
		data()
		{
			return {
				username: null,
				password: null,
				email_error:null,
			};
		},
		mounted()
		{
			console.log(process.env.VUE_APP_API_URL);
			this.$axios.get(`https://api.ix-index.com/test`)
			.then(response => {
				console.log(response.data);
			})
		},
		methods:{
			submitLogin()
			{
				const username = this.username;
				const password = this.password;
				this.$axios.post(`${process.env.VUE_APP_API_URL}/login/`, {username, password})
				.then(response => {
					const data = response.data;
					localStorage.setItem('access_token', data.access_token);
					localStorage.setItem('refresh_token', data.refresh_token);
					this.$axios.defaults.headers.Authorization = 'Bearer '+localStorage.getItem('access_token')
					this.$router.push({name: 'Dashboard'});

				})
				.catch(error => {
					this.email_error = error.response.data.message
				})
			}
		}
	}
</script>
<style>
@import '../../assets/auth/css/theme.css';

</style>

<template>
	<div class="container-fluid p-0">
		<div class="row mb-2 mb-xl-3">
			<div class="col-auto d-none d-sm-block">
				<h3>Real Time Weighting</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-12 d-flex">
				<div class="card flex-fill">
					
					<table class="table table-sm table-striped my-0">
						<thead>
							<tr>
								<th class="d-none d-xl-table-cell">Coin</th>
								<th class="d-none d-xl-table-cell">Price</th>
								<th class="d-none d-xl-table-cell">Weighting</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(crypto, name) in crypto_info" v-bind:key="name">
								<td class="d-none d-xl-table-cell"><img :src="getImagePath(name)" style="width:20px;"> {{ crypto_names[name] }}</td>
								<td class="d-none d-xl-table-cell">${{ crypto.price.toFixed(5) }}</td>
								<td class="d-none d-xl-table-cell">{{ (crypto.weighting * 100).toFixed(2) }}%</td>
							</tr>
						</tbody>
					</table>
					<div class="card-footer">
						<h5 class="card-title mb-0">Last Update: {{ last_updated_at }}</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import moment from 'moment';

	export default {
		name: "RealTimeWeighting",
		mounted() {
			// this.$toast.success('f');
			this.fetchData();
		},
		data()
		{
			return {
				crypto_info:[],
				crypto_names:{
					BTC: 'Bitcoin',
					ETH: 'Ethereum',
					XRP: 'XRP',
					ADA: 'Cardano',
					DOGE: 'Dogecoin',
					DOT: 'Polkadot',
					LTC: 'Litecoin',
					BCH: 'Bitcoin Cash',
					XLM: 'Stellar',
					SOL: 'Solana',
					VET: 'VeChain',
					MATIC: 'Polygon',
					LUNA: 'Terra',
					AVAX: 'Avalanche',
					ALGO: 'Algorand',
					ATOM: 'Cosmos',
					FTM: 'Fantom',
					TRX: 'TRON',
					ICP: 'Internet Computer',
					NEAR: 'NEAR Protocol',
				},
				last_updated_at:null,
				tokenName:null,
				accessToken:null,
				error:null,
				issuing:false,
				revoking:false,
			}
		},
		methods:{
			getImagePath(crypto)
			{
				return require(`../../assets/img/cryptos/${crypto}.png`)
			},
			async fetchData()
			{
				var response = await this.$axios.get(`${process.env.VUE_APP_API_URL}/v1/real-time/weighting`);
				this.crypto_info = response.data.data;
				this.last_updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
				const timestamp = moment().unix();
				setTimeout(this.fetchData, ((15 - timestamp % 15) * 1000));
			}
		}
	}
</script>

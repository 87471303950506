import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueClipboard from 'vue-clipboard2'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

axios.defaults.headers.common['Accept'] = 'application/json';

if(localStorage.getItem('access_token') != null)
{
	axios.defaults.headers.Authorization = 'Bearer '+localStorage.getItem('access_token')
}

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueToast, {
	position: 'top-right'
});

Vue.prototype.$axios = axios;
 
Vue.use(VueClipboard);

 new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
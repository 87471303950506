import Vue from 'vue'
import Router from 'vue-router'
import TokenIndex from '../views/token/TokenIndex.vue'
import DashboardLayout from '../layouts/DashboardLayout.vue'
import Register from '../views/auth/Register.vue'
import Login from '../views/auth/Login.vue'
import RealTimeIndex from '../views/index/RealTimeIndex.vue'
import RealTimeWeighting from '../views/weighting/RealTimeWeighting.vue'

Vue.use(Router)

const routes = [
	{
		path: "/register",
		component: Register,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/logout",
		name: "logout",
		component: {
			beforeRouteEnter()
			{
				localStorage.removeItem('access_token');
				localStorage.removeItem('refresh_token');
				window.location = '/login';
			}
		},
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/",
		component: DashboardLayout,
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				component: TokenIndex,
				meta: {
					requiresAuth: true
				}
				// () => import(/* webpackChunkName: "about" */ '../views/About.vue')
			},
			{
				path: "/real-time/index",
				name: "RealTimeIndex",
				component: RealTimeIndex,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: "/real-time/weighting",
				name: "RealTimeWeighting",
				component: RealTimeWeighting,
				meta: {
					requiresAuth: true
				}
			},
		],
		meta: {
			requiresAuth: true
		}
	},
]

const router = new Router({
	mode: "history",
	routes,
});

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('access_token');
	// console.log("Token:"+token);
	if(to.matched.some(record => record.meta.requiresAuth))
	{
		if (token == null) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			})
		}
		else
		{
			next();
		} 
	}
	else 
	{
		if(token == null)
			next()
		else
			next({ name: 'Dashboard'})
	}
	// else {
	// 	next()
	// }
})


export default router
